import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
/*import { Options, ChangeContext, PointerType, LabelType } from '@angular-slider/ngx-slider';*/
import { Loan } from '../calculator/Loan';
import { TaxRate } from './TaxRate';
import { PricingService } from '../pricing.service';

// will need to override the css styles here
@Component({
  selector: 'pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PricingComponent implements OnInit {

  @Input()
  loan: Loan;

  stateTaxRates: TaxRate[];

  //sliderCostTranslationFunction = (value: number, label: LabelType): string => {

  //  switch (label) {
  //    case LabelType.Low:
  //      return `${Math.round(value / 1000)}k`;
  //    case LabelType.High:
  //      return `${Math.round(value / 1000)}k`;
  //    default:
  //      return '';
  //  }
  //};

  sliderCustomValueToPosition = (val: number, minVal: number, maxVal: number): number => {
    val = Math.sqrt(val);
    minVal = Math.sqrt(minVal);
    maxVal = Math.sqrt(maxVal);
    const range: number = maxVal - minVal;
    return (val - minVal) / range;
  };

  sliderCustomPositionToValue = (percent: number, minVal: number, maxVal: number): number => {
    minVal = Math.sqrt(minVal);
    maxVal = Math.sqrt(maxVal);
    const value: number = percent * (maxVal - minVal) + minVal;
    return Math.pow(value, 2);
  };

  //priceSliderOptions: Options = {
  //  floor: 0,
  //  minLimit: 0,
  //  enforceRange: true,
  //  ceil: 1_000_000,
  //  //touchEventsInterval: // need to investigate chaning the value, and how to not get caught when scrolling
  //  showSelectionBar: true,
  //  showTicksValues: true,
  //  showOuterSelectionBars: false,
  //  showSelectionBarEnd: false,
  //  translate: this.sliderCostTranslationFunction,
  //  ticksArray: [10_000, 20_000, 30_000, 40_000, 50_000, 60_000, 70_000, 80_000, 90_000, 100_000, 200_000, 300_000, 400_000, 500_000, 600_000, 700_000, 800_000, 900_000],
  //  customValueToPosition: this.sliderCustomValueToPosition,
  //  customPositionToValue: this.sliderCustomPositionToValue
  //};

  discountSliderCeiling: number = 20_000;

  //discountSliderOptions: Options = {
  //  floor: 0,
  //  enforceRange: true,

  //  ceil: 20_000,
  //  showSelectionBar: true,
  //  showOuterSelectionBars: false,
  //  showSelectionBarEnd: false,
  //  translate: this.sliderCostTranslationFunction,
  //  customValueToPosition: this.sliderCustomValueToPosition,
  //  customPositionToValue: this.sliderCustomPositionToValue
  //};

  //tradeInSliderOptions: Options = {
  //  floor: 0,
  //  enforceRange: true,

  //  ceil: 1_000_000,
  //  showSelectionBar: true,
  //  showOuterSelectionBars: false,
  //  showSelectionBarEnd: false,
  //  translate: this.sliderCostTranslationFunction,
  //  customValueToPosition: this.sliderCustomValueToPosition,
  //  customPositionToValue: this.sliderCustomPositionToValue
  //};

  //downPaymentSliderOptions: Options = {
  //  floor: 0,
  //  enforceRange: true,
  //  ceil: 1_000_000,
  //  showSelectionBar: true,
  //  showOuterSelectionBars: false,
  //  showSelectionBarEnd: false,
  //  translate: this.sliderCostTranslationFunction,
  //  customValueToPosition: this.sliderCustomValueToPosition,
  //  customPositionToValue: this.sliderCustomPositionToValue
  //};

  constructor(private pricingService: PricingService, private ref: ChangeDetectorRef) {
    this.stateTaxRates = pricingService.generateTaxRates();
  }

  ngOnInit(): void {
    //this.discountSliderOptions.ceil = this.loan.price;
  }

  ngOnChanges(changes: SimpleChanges) {
    const newLoanData: Loan = <Loan><unknown>(changes.loan.currentValue);
    this.loan = newLoanData;

    //this.updateMaxDiscountAndDownPayment();
  }

  updateLoan() {
    this.loan.recalculate();
    //this.updateMaxDiscountAndDownPayment();
  }

  //updateMaxDiscountAndDownPayment() {
  //  // hack for updating max values
  //  const newDiscountOptions: Options = Object.assign({}, this.discountSliderOptions);
  //  newDiscountOptions.ceil = this.loan.price;
  //  this.discountSliderOptions = newDiscountOptions;

  //  const newDownPaymentOptions: Options = Object.assign({}, this.downPaymentSliderOptions);

  //  const newMaxDownPayment = this.loan.price - (this.loan.discount + this.loan.tradeIn)
  //  newDownPaymentOptions.ceil = newMaxDownPayment > 0 ? newMaxDownPayment : 0 ;
  //  this.downPaymentSliderOptions = newDownPaymentOptions;
  //}

  selectTaxRate(value: number) {
    this.loan.updateTaxSelection(value);
    this.ref.markForCheck();
  }

  /**
   * format number values 
   * @param value
   */
  formatThousands = (value: number) => {
    return this.pricingService.formatThousands(value);
  }

  /**
   * format percent values
   * @param value
   */
  formatPercent = (value: number) => {
    return this.pricingService.formatPercent(value);
  }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_PATH_INJECTABLE } from '../core/api-path.constant';

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  constructor(@Inject(API_PATH_INJECTABLE) private readonly API_PATH: string,
    private http: HttpClient) {

  }

  login() {
    return this.http.post<boolean>(`${this.API_PATH}/api/identity`, undefined);
  }
}

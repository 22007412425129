import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Period } from '../calculator/Period';
import { utils as XLSXUtils, writeFile } from 'xlsx';
import { WorkBook, WorkSheet } from 'xlsx/types';

@Component({
  selector: 'amoritization-schedule',
  templateUrl: './amoritization-schedule.component.html',
  styleUrls: ['./amoritization-schedule.component.scss']
})
export class AmoritizationScheduleComponent implements OnInit, AfterViewInit, OnChanges {

  displayedColumns: string[] = ['date', 'id', 'startingBalance', 'payment', 'interest', 'cumulativePrincipal', 'cumulativeInterest', 'endingBalance'];
  dataSource: MatTableDataSource<Period>;

  @Input()
  scheduleData: Period[];

  @ViewChild(MatSort)
  sort: MatSort;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.updateDataSource();
  }

  ngOnChanges(changes: SimpleChanges) {
    const newScheduleData: Period[] = <Period[]><unknown>(changes.scheduleData.currentValue);
    this.scheduleData = newScheduleData;
    this.updateDataSource();
  }

  fileName = 'calcmyride_amoritization_schedule.xlsx';
  fileExtension = '.xlsx';

  exportToExcel() {
    let wb: WorkBook;
    let header = [];

    const sheetName = "CalcMyRide";

    const ws: WorkSheet = XLSXUtils.json_to_sheet(this.scheduleData, { header });
    wb = XLSXUtils.book_new();
    XLSXUtils.book_append_sheet(wb, ws, sheetName);

    const xlsBlobUrl = writeFile(wb, `${this.fileName}${this.fileExtension}`);

    if (this.iOS()) {
      //window.location.href = xlsBlobUrl;

      const download = () => {
        const fileLink = document.createElement('a');
        fileLink.href = xlsBlobUrl;

        // it forces the name of the downloaded file
        fileLink.download = this.fileName;

        // triggers the click event
        fileLink.click();
      }

      setTimeout(download, 1000);

      // create <a> tag dinamically


      //window.location.href = xlsBlobUrl

      //let blob = new Blob([xlsBlobUrl]);
      //const url = window.URL.createObjectURL(blob);

      //const link: any = document.createElement('a');
      //link.download = `${this.fileName}`;
      //link.href = url;
      //link.click();

      //URL.revokeObjectURL(url);
      //URL.revokeObjectURL(xlsBlobUrl);
    } else {
      URL.revokeObjectURL(xlsBlobUrl)
    }

    //window.location.href = xlsBlobUrl;

    //blob.

    //let reader = new FileReader();
    //reader.readAsDataURL(xlsBlobUrl); // converts the blob to base64 and calls onload

    //reader.onload = function () {
    //  link.href = reader.result; // data url
    //  link.click();
    //};

    // free from memory
    
    //URL.revokeObjectURL(blob)
  }

  private updateDataSource() {
    this.dataSource = new MatTableDataSource<Period>(this.scheduleData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

}

<div class="mat-elevation-z8 amoritization-schedule mt-3">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Date
      </th>
      <td mat-cell *matCellDef="let period">
        {{period.date | date:'shortDate'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Period
      </th>
      <td mat-cell *matCellDef="let period">
        {{period.id}}
      </td>
    </ng-container>

    <ng-container matColumnDef="startingBalance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Starting Balance
      </th>
      <td mat-cell *matCellDef="let period">
        {{period.startingBalance | currency}}
      </td>
    </ng-container>

    <ng-container matColumnDef="payment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Payment
      </th>
      <td mat-cell *matCellDef="let period">
        {{period.payment | currency}}
      </td>
    </ng-container>

    <ng-container matColumnDef="interest">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Interest
      </th>
      <td mat-cell *matCellDef="let period">
        {{period.interest | currency}}
      </td>
    </ng-container>

    <ng-container matColumnDef="cumulativePrincipal">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Cumulative Principal
      </th>
      <td mat-cell *matCellDef="let period">
        {{period.cumulativePrincipal | currency}}
      </td>
    </ng-container>

    <ng-container matColumnDef="cumulativeInterest">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Cumulative Interest
      </th>
      <td mat-cell *matCellDef="let period">
        {{period.cumulativeInterest | currency}}
      </td>
    </ng-container>

    <ng-container matColumnDef="endingBalance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Ending Balance
      </th>
      <td mat-cell *matCellDef="let period">
        {{period.endingBalance | currency}}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="12" [pageSizeOptions]="[12, 24, 36]" showFirstLastButtons></mat-paginator>
</div>
<div class="row mt-2">
  <!--<div class="col-6">
    <mat-form-field>
      <mat-label>
        Filter
      </mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input>
    </mat-form-field>
  </div>-->
  <div class="col-12">
    <button mat-raised-button color="accent" aria-label="Download" class="save-button text-uppercase" (click)="exportToExcel()">
      Save Schedule
      <mat-icon>save_alt</mat-icon>
    </button>
  </div>
</div>

/*import { NgxSliderModule } from '@angular-slider/ngx-slider';*/
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import * as echarts from 'echarts';
import { NgxEchartsModule } from 'ngx-echarts';

import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { environment as env } from "../environments/environment";
import { AmoritizationScheduleComponent } from './amoritization-schedule/amoritization-schedule.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { CoreModule } from './core/core.module';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { FinancingComponent } from './financing/financing.component';
import { GoogleAdsenseModule } from './google-adsense/google-adsense.module';
import { LandingPageModule } from "./landing-page/landing-page.module";
import { LayoutModule as AppLayoutModule } from "./layout/layout.module";
import { LoanSummaryComponent } from './loan-summary/loan-summary.component';
import { LoanComponent } from './loan/loan.component';
import { PriceWizardComponent } from './price-wizard/price-wizard.component';
import { PricingComponent } from './pricing/pricing.component';
import { SummaryBarChartComponent } from './summary-bar-chart/summary-bar-chart.component';
import { SummaryGraphComponent } from './summary-graph/summary-graph.component';
import { QuickCalcComponent } from './quick-calculator/quick-calc.component';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SupportComponent } from './support/support.component';
import { ShareModule } from 'ngx-sharebuttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShareButtonComponent } from './share-button/share-button.component';
import { PlaygroundComponent } from './playground/playground.component';


//export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    FetchDataComponent,
    CalculatorComponent,
    AmoritizationScheduleComponent,
    PricingComponent,
    FinancingComponent,
    LoanComponent,
    LoanSummaryComponent,
    SummaryGraphComponent,
    PriceWizardComponent,
    SummaryBarChartComponent,
    QuickCalcComponent,
    SupportComponent,
    ShareButtonComponent,
    PlaygroundComponent
  ],
  imports: [
    AppLayoutModule,
    CoreModule,
    LandingPageModule,
    GoogleAdsenseModule,
    CommonModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    ShareModule,
    ShareIconsModule,
 /*   NgxSliderModule,*/
    NgxMaskDirective,
    NgxMaskPipe,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    //ApiAuthorizationModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    MatSliderModule,
    MatCardModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatStepperModule,
    MatTooltipModule,
    MatDialogModule,
    MatExpansionModule,
    MatMenuModule,
    AuthModule.forRoot({
      ...env.auth,
      //authorizationParams: {
      //    redirect_uri: window.location.origin
      //  },
      httpInterceptor: {
        allowedList: [`${env.API_PATH}/api/*`],
      },
    }),
    AppRoutingModule,
    FontAwesomeModule
  ],
  providers: [
    provideNgxMask(),
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: Window,
      useValue: window,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

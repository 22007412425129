/**
 * An amoritization period
 * */
export class Period {

  /**
   * unique identifier and current period
   **/
  id: number

  nextId(): number {
    return this.id + 1;
  }

  /**
   * date of the period
   **/ 
  date: Date;

  nextDate(): Date {

    const nextMonth: number = this.date.getMonth() + 1;

    const nextMonthDate = new Date(this.date);
    nextMonthDate.setMonth(nextMonth, 1);

    return nextMonthDate;
  }

  /**
   * balance at the start of the period
   **/ 
  principal: number

  /**
   * total amount of payment (should be constant)
   **/
  payment: number;

  /**
   * interest paid for period
   **/
  interest: number;

  /**
   * portion of loan payment that goes towards the principal
   **/
  startingBalance: number;

  /**
   * cumulative principal paid
   **/ 
  cumulativePrincipal: number;

  /**
   * cumulative interest paid
   **/ 
  cumulativeInterest: number;

  /**
   * outstanding principal to be paid
   **/ 
  endingBalance: number;

  /**
   * interest rate for this period
   **/ 
  interestRate: number;

  /**
   * length 
   */
  periodLength: number;

  /**
   * Excel headers for amoritization schedule
   * */
  public static excelHeaders(): string[] {
    return ["id",
      "date",
      "principal",
      "payment",
      "interest",
      "starting balance",
      "cumulative principal",
      "cumulative interest",
      "ending balance",
      "interest rate",
      "period length",
    ];
  }

  constructor(payment: number, startingBalance: number, currentPeriod: number,  date: Date, interestRate: number, periodLength: number, previousCumPrincipal: number, previousCumInterest: number) {
   
    this.payment = payment;
    this.startingBalance = startingBalance;
    this.id = currentPeriod;
    this.date = date;
    this.interestRate = interestRate;
    this.periodLength = periodLength;

    this.cumulativePrincipal = previousCumPrincipal;
    this.cumulativeInterest = previousCumInterest;

    // perform calculations
    this.calcInterestByPrincipal(this.startingBalance);
    this.calcPrincipalPayment();
    this.calcEndingBalancing();
    this.calcCumPrincipal();
    this.calcCumInterest();
  }

  /**
   * Calculate the interest per principal
   * @param startingPrincipal
   */
  private calcInterestByPrincipal(startingPrincipal: number): void {
    this.interest =  (this.interestRate / this.periodLength) * startingPrincipal;
  }

  private calcPrincipalPayment() {
    this.principal = this.payment - this.interest;
  }

  private calcEndingBalancing() {
    this.endingBalance = this.startingBalance - this.principal;
  }

  private calcCumPrincipal(): void {
    this.cumulativePrincipal = this.cumulativePrincipal + this.principal;
  }

  private calcCumInterest(): void {
    this.cumulativeInterest = this.cumulativeInterest + this.interest;
  }
}

<div class="container overflow-auto container-height">
    <div class="row mt-4">
        <div class="col-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        About
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    According to research by <a href="https://www.prnewswire.com/news-releases/average-new-vehicle-prices-up-nearly-3-year-over-year-in-february-2020-according-to-kelley-blue-book-301015050.html">Kelly Blue Book</a>,
                    the average new vehicle purchase was roughly $37,000 in 2020. While the average purchase price has grown signficantly, so has the average auto loan.
                    <br />
                    <br />
                    Depending on the consumer credit score, <a href="https://www.experian.com/blogs/ask-experian/what-is-the-average-length-of-a-car-loan/">Experian</a> reports
                    the average length of an automotive loan is 72 months!
                    <br />
                    <br />
                    Americans are spending more on their rides than ever before and taking longer than ever to pay off their automotive purchase.
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-card class="mt-3">
                <mat-card-header>
                    <mat-card-title>
                        Root Cause
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    CalcMyRide believes that auto loan consumers are being pursuaded to pay more for their vehicle because they are being
                    conditioned to focus on the monthly payment and not the total cost of purchase.
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-card class="mt-3">
                <mat-card-header>
                    <mat-card-title>
                        Difference Maker
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    CalcMyRide allows consumers to easily calculate the true price a vehicle purchase rather then the monthly payment.
                    <br />
                    <br />
                    We believe in giving consumers the tools they need to understand how and where their hard earned capital is being deployed.
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { faTwitter, faFacebook, faLinkedin, faReddit } from '@fortawesome/free-brands-svg-icons';
import { faLink, faSms, faEnvelope } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit, OnChanges {
  faTwitter = faTwitter;
  faFacebook = faFacebook;
  faLinkedin = faLinkedin;
  faReddit = faReddit;

  faLink = faLink;
  faSms = faSms;
  faEnvelop = faEnvelope;

  @Input()
  loanAsQueryParameters: string;

  @Input()
  pageUrl: string;

  loanAndRouteAsUrl: string;

  constructor() { }

  ngOnInit(): void {
    this.loanAndRouteAsUrl = this.buildShareUrlTest(this.pageUrl, this.loanAsQueryParameters)
  }

  ngOnChanges(changes: SimpleChanges) {
    const newLoanData: string = <string><unknown>(changes.loanAsQueryParameters.currentValue);
    this.loanAsQueryParameters = newLoanData;

    this.loanAndRouteAsUrl = this.buildShareUrlTest(this.pageUrl, this.loanAsQueryParameters);
  }

  private buildShareUrlTest(pageUrl: string, loanAsQueryParameters: string): string {
    return `${pageUrl}?${loanAsQueryParameters}`;
  }

}

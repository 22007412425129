import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { AmoritizationScheduleComponent } from '../amoritization-schedule/amoritization-schedule.component';
import { Loan } from '../calculator/Loan';
import html2canvas from 'html2canvas';

@Component({
  selector: 'loan-summary',
  templateUrl: './loan-summary.component.html',
  styleUrls: ['./loan-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoanSummaryComponent implements OnInit, OnChanges {

  @ViewChild(MatAccordion)
  accordion: MatAccordion;

  @ViewChild('loanSchedule')
  loanASchedule: AmoritizationScheduleComponent;

  @Input()
  loan: Loan;

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    const newLoanData: Loan = <Loan><unknown>(changes.loan.currentValue);
    this.loan = newLoanData;
  }

  /**
   * format percent values
   * @param value
   */
  formatPercent(value: number) {
    return `${value}%`;
  }

  /**
   * details on summaries
   **/
  saveDetailSummary() {

    const name = "calcmyride_details_summary.png";
    const detailsPage = document.getElementsByClassName('details-summary-html2canvas')[0] as HTMLElement;

    html2canvas(detailsPage).then((canvas) => {
      this.saveAs(canvas.toDataURL(), name);
    });
  }

  /**
   * save the detail timeline
   **/
  saveDetailTimeline() {
    const name = "calcmyride_details_timeline.png";
    const detailsPage = document.getElementsByClassName('details-timeline-html2canvas')[0] as HTMLElement;

    html2canvas(detailsPage).then((canvas) => {
      this.saveAs(canvas.toDataURL(), name);
    });
  }

  saveAs(uri, filename) {

    const link = document.createElement('a');

    link.href = uri;
    link.download = filename;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
  }
}

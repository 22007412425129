import { Injectable } from '@angular/core';
import { TaxRate } from './pricing/TaxRate';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  constructor() { }

  /**
  * format number values 
  * @param value
  */
  formatThousands(value: number): string {
    if (value >= 1000) {
      return "$" + `${Math.round(value / 1000)}k`;
    }

    return value?.toString();
  }

  /**
   * format loan hint values to give an idea of other loan names
   * @param valueA
   * @param valueB
   */
  formatABThousandValues(nameA: string, valueA: number, nameB: string, valueB: number): string {

    if (valueA >= 1000 && valueB >= 1000) {
      return `${nameA} ${this.formatThousands(valueA)}, ${nameB} ${this.formatThousands(valueB)}`;
    }
    else if (valueA >= 1000 && valueB < 1000) {
      return `${nameA} ${this.formatThousands(valueA)}, ${nameB} $${valueB}`;
    }
    else if (valueA < 1000 && valueB >= 1000) {
      return `${nameA} $${valueA}, ${nameB} ${this.formatThousands(valueB)}`;
    }

    return `${nameA} $${valueA}, ${nameB} $${valueB}`;
  }

  /**
   * format percent values
   * @param value
   */
  formatPercent(value: number): string {
    return `${value}%`;
  }

  /**
   * format loan hint values for percentages
   * @param nameA
   * @param valueA
   * @param nameB
   * @param valueB
   */
  formatABPercent(nameA: string, valueA: number, nameB: string, valueB: number): string {
    return `${nameA} ${this.formatPercent(valueA)}, ${nameB} ${this.formatPercent(valueB)}`;
  }

  /**
   * generate US tax rate valuse
   * */
  generateTaxRates(): TaxRate[] {

    return [
      new TaxRate("None", "N/A", 0),
      new TaxRate("Alabama", "AL", 200),
      new TaxRate("Alaska", "AK", 0),
      new TaxRate("Arizona", "AZ", 560),
      new TaxRate("Arkansas", "AR", 650),
      new TaxRate("California", "CA", 725),
      new TaxRate("Colorado", "CO", 290),
      new TaxRate("Connecticut", "CT", 635),
      new TaxRate("Delaware", "DE", 0),
      new TaxRate("Florida", "FL", 600),
      new TaxRate("Georgia", "GA", 660),

      new TaxRate("Hawaii", "HI", 400),
      new TaxRate("Idaho", "ID", 600),
      new TaxRate("Illinois", "IL", 625),
      new TaxRate("Indiana", "IN", 700),
      new TaxRate("Iowa", "IA", 500),
      new TaxRate("Kansas", "KS", 750),
      new TaxRate("Kentucky", "KY", 600),
      new TaxRate("Louisiana", "LA", 400),
      new TaxRate("Maine", "ME", 550),
      new TaxRate("Maryland", "MD", 600),
      new TaxRate("Massachusetts", "MA", 625),

      new TaxRate("Michigan", "MI", 600),
      new TaxRate("Minnesota", "MN", 650),
      new TaxRate("Mississippi", "MS", 500),
      new TaxRate("Missouri", "MO", 423),
      new TaxRate("Montana", "MT", 0),
      new TaxRate("Nebraska", "NE", 550),
      new TaxRate("Nevada", "NV", 825),
      new TaxRate("New Hampshire", "NH", 0),
      new TaxRate("New Jersey", "NJ", 663),
      new TaxRate("New Mexico", "NM", 400),
      new TaxRate("New York", "NY", 400),

      new TaxRate("North Carolina", "NC", 300),
      new TaxRate("North Dakota", "ND", 500),
      new TaxRate("Ohio", "OH", 575),
      new TaxRate("Oklahoma", "OK", 325),
      new TaxRate("Oregon", "OR", 0),
      new TaxRate("Pennsylvania", "PA", 600),
      new TaxRate("Rhode Island", "RI", 700),
      new TaxRate("South Carolina", "SC", 500),
      new TaxRate("South Dakota", "SD", 400),
      new TaxRate("Tennessee", "TN", 700),

      new TaxRate("Texas", "TX", 625),
      new TaxRate("Utah", "UT", 685),
      new TaxRate("Vermont", "VT", 600),
      new TaxRate("Virginia", "VA", 415),
      new TaxRate("Washington", "WA", 650),
      new TaxRate("West Virginia", "WV", 600),
      new TaxRate("Wisconsin", "WI", 500),
      new TaxRate("Wyoming", "WY", 600)
    ]
  }

  selectTaxRate(index: number, taxRates: TaxRate[]): TaxRate {

    if (index >= 0  && index < taxRates.length) {
      return taxRates[index];
    }

    return undefined;
  }
}

<div class="container quick-calc-container">
    <div class="row mt-2">
        <div class="col-12">
            <mat-card appearance="outlined">
                <mat-card-header>
                    <mat-card-title>
                        Quick Calc Auto Loans
                    </mat-card-title>
                </mat-card-header>
            </mat-card>
        </div>
    </div>
    <div class="row mt-2 quick-calc-inputs">
        <div class="col-sm-4 col-xs-12">
            <mat-card class="example-card">
                <mat-card-header>
                    <mat-card-title>
                        Terms
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="wizard-input-length mt-3">
                                <mat-label>
                                    Price
                                </mat-label>
                                <input matInput
                                       [(ngModel)]="loan.price"
                                       (ngModelChange)="loan.recalculate()"
                                       type="text"
                                       mask="separator"
                                       thousandSeparator=","
                                       separatorLimit="999999"
                                       inputmode="numeric"
                                       placeholder=""
                                       name="salesPrice"
                                       class="wizard-input-text"
                                       cdkFocusInitial>
                                <span matPrefix>
                                    &nbsp;$
                                </span>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="wizard-input-length">
                                <mat-label>
                                    Loan Length
                                </mat-label>
                                <input matInput
                                       [(ngModel)]="loan.loanLength"
                                       (ngModelChange)="loan.recalculate()"
                                       type="number"
                                       min="0"
                                       step="1"
                                       inputmode="numeric"
                                       placeholder=""
                                       name="loanLength"
                                       class="wizard-input-text">
                                <span matSuffix>
                                    months&nbsp;
                                </span>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="wizard-input-length">
                                <mat-label>
                                    Interest Rate
                                </mat-label>
                                <input matInput
                                       [(ngModel)]="loan.rate"
                                       (ngModelChange)="loan.recalculate()"
                                       inputmode="decimal"
                                       type="text"
                                       mask="separator.3"
                                       separatorLimit="30"
                                       placeholder=""
                                       name="rate"
                                       class="wizard-input-text">
                                <span matSuffix>
                                    %&nbsp;
                                </span>
                                <mat-hint align="end">
                                    {{loan.totalInterest | currency}}
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="wizard-input-length">
                                <mat-label>
                                    Down Payment
                                </mat-label>
                                <input matInput
                                       [(ngModel)]="loan.downPayment"
                                       (ngModelChange)="loan.recalculate()"
                                       type="text"
                                       mask="separator"
                                       thousandSeparator=","
                                       separatorLimit="999999" min="0"
                                       step="100"
                                       inputmode="numeric"
                                       placeholder=""
                                       name="downPayment"
                                       class="wizard-input-text">
                                <span matPrefix>
                                    &nbsp;$
                                </span>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="wizard-input-length">
                                <mat-label>
                                    Trade-In
                                </mat-label>
                                <input matInput
                                       [(ngModel)]="loan.tradeIn"
                                       (ngModelChange)="loan.recalculate()"
                                       type="text"
                                       mask="separator"
                                       thousandSeparator=","
                                       separatorLimit="999999"
                                       inputmode="numeric"
                                       placeholder=""
                                       name="tradeIn"
                                       class="wizard-input-text">
                                <span matPrefix>
                                    &nbsp;$
                                </span>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="fill" class="wizard-input-length">
                                <mat-label>
                                    State Tax
                                </mat-label>
                                <mat-select [(ngModel)]="loan.taxRate" name="stateSalesTax" (selectionChange)="selectTaxRate($event.value)">
                                    <mat-option [value]="0"></mat-option>
                                    <mat-option *ngFor="let stateRate of stateTaxRates" [value]="stateRate.percent">
                                        {{stateRate.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions align="end" class="flex-justified-card-footer-layout">
                    <button mat-mini-fab
                            matTooltip="Open in Advanced"
                            (click)="goToAdvanced()"
                            matTooltipPosition="above"
                            color="accent"
                            aria-label="Example icon button with a home icon">
                        <mat-icon>rocket launch</mat-icon>
                    </button>
                    <share-button [loanAsQueryParameters]="loan.loanAsQueryParameters" [pageUrl]="quickCalUrl" #shareButtonCalc></share-button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="col-sm-8 col-xs-8 mt-2 margin-bottom-pricing">
            <mat-card appearance="outlined" class="example-card">
                <mat-card-header>
                    <mat-card-title>
                        Pricings
                    </mat-card-title>
                    <mat-card-subtitle>
                        Breakdown of the Total Cost and Monthly Cost of vehicle purchase
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <loan-summary [loan]="loan" #loanSummary></loan-summary>
                </mat-card-content>
                <mat-card-actions align="end" class="flex-justified-card-footer-layout">
                    <button mat-mini-fab
                            matTooltip="Open in Advanced"
                            (click)="goToAdvanced()"
                            matTooltipPosition="above"
                            color="accent"
                            aria-label="Example icon button with a home icon">
                        <mat-icon>rocket launch</mat-icon>
                    </button>
                    <div fxFlex></div>
                    <share-button [loanAsQueryParameters]="loan.loanAsQueryParameters" [pageUrl]="quickCalUrl" #shareButtonReview></share-button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    </div>
</div>

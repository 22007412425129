<!--<button mat-icon-button aria-label="Login" (click)="loginWithRedirect()" *ngIf="!(isHandset$ | async)">
  Login
</button>-->

<a mat-list-item routerLink="/profile" routerLinkActive="active" (click)="loginWithRedirect()" class="login-button">
  Login
</a>

<button mat-icon-button aria-label="Login" (click)="loginWithRedirect()" *ngIf="(isHandset$ | async)" class="login-button">
  <mat-icon>login</mat-icon>
</button>

<button mat-mini-fab [matMenuTriggerFor]="shareMenu" color="accent" aria-label="Share icon" matTooltip="Share">
    <mat-icon>
        share
    </mat-icon>
</button>
<mat-menu #shareMenu="matMenu" yPosition="above">
    <button mat-menu-item shareButton="facebook" #fbBtn="shareButton" [url]="loanAndRouteAsUrl">
        <fa-icon *ngIf="fbBtn.icon" [icon]="faFacebook" [style.color]="fbBtn?.color" class="share-icon"></fa-icon>
        <span>Facebook</span>
    </button>
    <button mat-menu-item shareButton="twitter" #twBtn="shareButton" [url]="loanAndRouteAsUrl">
        <fa-icon *ngIf="twBtn.icon" [icon]="faTwitter" [style.color]="twBtn?.color" class="share-icon"></fa-icon>
        <span>Twitter</span>
    </button>
    <button mat-menu-item shareButton="linkedin" #lkInBtn="shareButton" [url]="loanAndRouteAsUrl">
        <fa-icon *ngIf="lkInBtn.icon" [icon]="faLinkedin" [style.color]="lkInBtn?.color" class="share-icon"></fa-icon>
        <span>LinkedIn</span>
    </button>
    <button mat-menu-item shareButton="reddit" #redditBtn="shareButton" [url]="loanAndRouteAsUrl">
        <fa-icon *ngIf="redditBtn.icon" [icon]="faReddit" [style.color]="redditBtn?.color" class="share-icon"></fa-icon>
        <span>Reddit</span>
    </button>
    <button mat-menu-item shareButton="email" #emailBtn="shareButton" [url]="loanAndRouteAsUrl">
        <fa-icon *ngIf="emailBtn.icon" [icon]="faEnvelop" [style.color]="emailBtn?.color" class="share-icon"></fa-icon>
        <span>Email</span>
    </button>
    <button mat-menu-item shareButton="sms" #smsBtn="shareButton" [url]="loanAndRouteAsUrl">
        <fa-icon *ngIf="smsBtn.icon" [icon]="faSms" [style.color]="smsBtn?.color" class="share-icon"></fa-icon>
        <span>SMS</span>
    </button>
    <button mat-menu-item shareButton="copy" #copyBtn="shareButton" [url]="loanAndRouteAsUrl">
        <fa-icon *ngIf="copyBtn.icon" [icon]="faLink" [style.color]="copyBtn?.color" class="share-icon"></fa-icon>
        <span>Copy Link</span>
    </button>
</mat-menu>

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption, graphic } from 'echarts';

@Component({
  selector: 'summary-bar-chart',
  templateUrl: './summary-bar-chart.component.html',
  styleUrls: ['./summary-bar-chart.component.scss']
})
export class SummaryBarChartComponent implements OnInit {

  chartOption: EChartsOption;

  @Input()
  price: number;

  @Input()
  totalInterest: number;

  @Input()
  salesTax: number;

  ngOnInit(): void {
    this.setOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setOptions();
  }

  setOptions(): void {

    if (this.price?.toString() === "") {
      this.price = 0;
      this.totalInterest = 0;
      this.totalInterest = 0;
    }

    const priceColor = new graphic.LinearGradient(0, 0, 0, 1, [{
      offset: 0,
      color: 'rgba(76, 175, 80, 1)'
    },
    {
      offset: 1,
      color: 'rgba(76, 175, 80, .3)'
    }
    ]);

    const interestColor = new graphic.LinearGradient(0, 0, 0, 1, [{
      offset: 0,
      color: 'rgba(255,193,7,1)'
    },
    {
      offset: 1,
      color: 'rgba(255,193,7,.3)'
    }
    ]);


    const taxColor = new graphic.LinearGradient(0, 0, 0, 1, [{
      offset: 0,
      color: 'rgba(255,87,34,1)'
    },
    {
      offset: 1,
      color: 'rgba(255,87,34, .3)'
    }
    ]);

    const standardPurple = new graphic.LinearGradient(0, 0, 0, 1, [{
      offset: 0,
      color: 'rgba(84, 112, 198, 1)'
    },
    {
      offset: 1,
      color: 'rgba(84, 112, 198, .3)'
    }
    ]);

    this.chartOption = {
      legend: {
        data: ['Price', "Interest", "Tax"],
        align: 'left',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      xAxis: {
        type: 'category',
        data: ["Total Cost"]
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (number, index: number) => {
            return `${number / 1000}K`;
          }
        },
      },
      series: [
        {
          name: "Price",
          type: 'bar',
          stack: 'cost',
          color: priceColor,
          emphasis: {
            focus: 'series'
          },
          data: [this.price.toFixed(2).toString()]
        },
        {
          name: "Interest",
          type: 'bar',
          stack: 'cost',
          color: interestColor,
          emphasis: {
            focus: 'series'
          },
          data: [this.totalInterest.toFixed(2).toString()]
        },
        {
          name: "Tax",
          type: 'bar',
          stack: 'cost',
          color: taxColor,
          emphasis: {
            focus: 'series'
          },
          data: [this.salesTax.toFixed(2).toString()]
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: (idx) => idx * 5,
    }
  }

}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'summary-graph',
  templateUrl: './summary-graph.component.html',
  styleUrls: ['./summary-graph.component.scss']
})
export class SummaryGraphComponent implements OnInit, OnChanges {

  chartOptions: EChartsOption;

  @Input()
  xAxisPeriodSeries: string[];

  @Input()
  cumulativeBalanceSeries: number[];

  @Input()
  cumulativePrincipalSeries: number[];

  @Input()
  cumulativeInterestSeries: number[];

  constructor() { }

  ngOnInit(): void {
    this.setOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setOptions();
  }

  setOptions() {

    this.chartOptions = {
      //title: {
      //  textAlign: "left",
      //  //left: 'center',
      //  text: `Loan${this.loan.name}`,
      //},
      legend: {
        data: ['Balance', 'Principal', "Interest"],
        align: 'left',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      toolbox: {
        show: false,
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          dataView: { readOnly: false },
          magicType: { type: ['line', 'bar'] },
          restore: {},
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: this.xAxisPeriodSeries,
        axisLabel: {
          show: true
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (number, index: number) => {
            return `${number/1000}K`;
          }
        },
      },
      series: [{
        name: "Balance",
        data: this.cumulativeBalanceSeries,
        type: 'bar',
        //type: 'line',
        //areaStyle: {}
        animationDelay: (idx) => idx * 10,
        markPoint: {
          data: [
            {
              x: 18,
              y: 40_000,
              value: "40k",
              //type: 'max',
              name: 'Total Cost',
            }//,
            //{ type: 'min', name: '最小值' }
          ]
        },
      },
        {
        name: "Principal",
        data: this.cumulativePrincipalSeries,
        //type: 'line',
        //areaStyle: {}
        type: 'bar',
        animationDelay: (idx) => idx * 10 + 100,
        //markPoint: {
        //  data: [
        //    { type: 'max', name: 'Test Max' },
        //    { type: 'min', name: 'Test Min' }
        //  ]
        //},
        //markLine: {
        //  data: [
        //    { type: 'average', name: 'Average' }
        //  ]
        //}
      },
        {
        name: "Interest",
        data: this.cumulativeInterestSeries,
        type: 'line',
        areaStyle: {}
      },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: (idx) => idx * 5,
    }
  }
}

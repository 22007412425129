import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityService } from '../identity.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  constructor(private router: Router, private identityService: IdentityService) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.identityService.login().subscribe(result => {
      this.router.navigate(['/calculator'])
    }, error => {
      console.log(error);
      this.router.navigate(['/error'])
    });
  }
}

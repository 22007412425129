<!--<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon class="close-icon">
  close
  </mat-icon>
</button>-->
<button class="close" mat-button (click)="closePriceWizard()">
  <mat-icon>
    close
  </mat-icon>
</button>
<mat-dialog-content class="mat-typography">
  <mat-horizontal-stepper>

    <!--Price-->
    <mat-step label="Price" state="price">
      <div class="row">
        <div class="col-12">
          <p class="wizard-loan-title">
            {{loan.fullName}}
          </p>
          <h2 class="text-uppercase text-center">
            Price
          </h2>
        </div>
        <div class="col-11 field-explanation" *ngIf="showExplanation">
          Some folks want you to focus on your monthly payment but that's not what truly matters when purchasing a vehicle. The most important number in the purchase of any vehicle is the price.
          Focusing on price keeps vehicle purchases reasonable and within budget. <b>Enter the price of your vehicle.</b>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <mat-form-field appearance="outline" class="wizard-input-length">
            <mat-label>
              Price
            </mat-label>
            <input matInput
                   [(ngModel)]="loan.price"
                   (ngModelChange)="loan.recalculate()"
                   type="text"
                   mask="separator"
                   thousandSeparator=","
                   separatorLimit="999999"
                   inputmode="numeric"
                   placeholder=""
                   name="salesPrice"
                   class="wizard-input-text"
                   cdkFocusInitial>
              <button mat-icon-button matSuffix color="primary" (click)="toggleExplanation()">
                <mat-icon>
                  info
                </mat-icon>
              </button>
              <span matPrefix>
                &nbsp;$
              </span>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-slider class="input-slider"
                      color="accent"
                      discrete
                      [displayWith]="formatThousands"
                      [max]="999999"
                      [min]="0"
                      [step]="1000"
                      [showTickMarks]="true">
            <input matSliderThumb
            (ngModelChange)="loan.recalculate()"
            [(ngModel)]="loan.price"
            name="salesPriceSlider" />
          </mat-slider>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <button mat-flat-button color="primary" matStepperNext class="text-uppercase stage-button">
            Next
            <mat-icon iconPositionEnd>
              east
            </mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <!--Discount-->
    <mat-step label="Discount" state="discount">
      <div class="row">
        <div class="col-12">
          <p class="wizard-loan-title">
            {{loan.fullName}}
          </p>
          <h2 class="text-uppercase text-center">
            Discount
          </h2>
        </div>
        <div class="col-12 field-explanation" *ngIf="showExplanation">
          Many auto dealerships will offer manufacturer rebates, special holiday pricing, military discounts or potentially you may be able to negotiate a better price than what has been listed.
          <b>Enter any discounts as a combined value.</b>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <mat-form-field appearance="outline" class="wizard-input-length">
            <mat-label>
              Discounts
            </mat-label>
            <input matInput
                   [(ngModel)]="loan.discount"
                   (ngModelChange)="loan.recalculate()"
                   type="text"
                   mask="separator"
                   thousandSeparator=","
                   separatorLimit="999999"
                   inputmode="numeric"
                   name="discount"
                   class="wizard-input-text">
            <button mat-icon-button matSuffix color="primary" (click)="toggleExplanation()">
              <mat-icon>
                info
              </mat-icon>
            </button>
            <span matPrefix>
              &nbsp;$
            </span>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-slider class="input-slider"
                      color="accent"
                      discrete
                      [displayWith]="formatThousands"
                      [max]="loan.price"
                      [min]="0"
                      [step]="1000"
                      [showTickMarks]="true">
            <input matSliderThumb (ngModelChange)="loan.recalculate()" [(ngModel)]="loan.discount" />
          </mat-slider>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <button mat-raised-button matStepperPrevious class="text-uppercase stage-button">
            <mat-icon>
              chevron_left
            </mat-icon>
            Back
          </button>
        </div>
        <div class="col-6">
          <button mat-raised-button color="primary" matStepperNext class="float-right text-uppercase stage-button">
            Next
            <mat-icon iconPositionEnd>
              east
            </mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <!--Trade In-->
    <mat-step label="Trade-In" state="tradeIn">
      <div class="row">
        <div class="col-12">
          <p class="wizard-loan-title">
            {{loan.fullName}}
          </p>
          <h2 class="text-uppercase text-center">
            Trade-In
          </h2>
        </div>
        <div class="col-12 field-explanation" *ngIf="showExplanation">
          Trading in your existing vehicle will help reduce the overall cost of your purchase. You can use a 3rd party service such as <a href="https://www.kbb.com/whats-my-car-worth/">Kelly Blue Book</a>
          or <a href="https://www.edmunds.com/tmv.html">Edmunds True Market Value</a> to value your car and then <b>enter its value here.</b>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <mat-form-field appearance="outline" class="wizard-input-length">
            <mat-label>
              Trade-In
            </mat-label>
            <input matInput
                   [(ngModel)]="loan.tradeIn"
                   (ngModelChange)="loan.recalculate()"
                   type="text"
                   mask="separator"
                   thousandSeparator=","
                   separatorLimit="999999"
                   inputmode="numeric"
                   placeholder=""
                   name="tradeIn"
                   class="wizard-input-text">
            <button mat-icon-button matSuffix color="primary" (click)="toggleExplanation()">
              <mat-icon>
                info
              </mat-icon>
            </button>
            <span matPrefix>
              &nbsp;$
            </span>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-slider class="input-slider"
                      color="accent"
                      discrete
                      [displayWith]="formatThousands"
                      [max]="100000"
                      [min]="0"
                      [step]="1000"
                      [showTickMarks]="true">
             <input matSliderThumb (ngModelChange)="loan.recalculate()" [(ngModel)]="loan.tradeIn" />
          </mat-slider>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <button mat-raised-button matStepperPrevious class="text-uppercase stage-button">
            <mat-icon>
              chevron_left
            </mat-icon>
            Back
          </button>
        </div>
        <div class="col-6">
          <button mat-raised-button color="primary" matStepperNext class="float-right text-uppercase stage-button">
            Next
            <mat-icon iconPositionEnd>
              east
            </mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <!--Down Payment-->
    <mat-step label="Down Payment" state="downPayment">
      <div class="row">
        <div class="col-12">
          <p class="wizard-loan-title">
            {{loan.fullName}}
          </p>
          <h2 class="text-uppercase text-center">
            Down Payment
          </h2>
        </div>
        <div class="col-12 field-explanation" *ngIf="showExplanation">
          The down payment is the cold hard cash you will put into the the initial price of a vehicle. The more down payment you can pay the better. In the long run,
          a larger down payment means you pay off your car faster and save money on interest. <b>Enter your down payment here.</b>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <mat-form-field appearance="outline" class="wizard-input-length">
            <input matInput
                   [(ngModel)]="loan.downPayment"
                   (ngModelChange)="loan.recalculate()"
                   type="number"
                   min="0"
                   step="100"
                   inputmode="numeric"
                   placeholder=""
                   name="downPayment"
                   class="wizard-input-text">
            <button mat-icon-button matSuffix color="primary" (click)="toggleExplanation()">
              <mat-icon>
                info
              </mat-icon>
            </button>
            <span matPrefix>
              &nbsp;$
            </span>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-slider class="input-slider"
                      color="accent"
                      discrete
                      [displayWith]="formatThousands"
                      [max]="loan.price"
                      [min]="0"
                      [step]="1000"
                      [showTickMarks]="true">
            <input matSliderThumb (ngModelChange)="loan.recalculate()" [(ngModel)]="loan.downPayment" />
          </mat-slider>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <button mat-raised-button matStepperPrevious class="text-uppercase stage-button">
            <mat-icon>
              chevron_left
            </mat-icon>
            Back
          </button>
        </div>
        <div class="col-6">
          <button mat-raised-button color="primary" matStepperNext class="float-right text-uppercase stage-button">
            Next
            <mat-icon iconPositionEnd>
              east
            </mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <!--Taxes-->
    <mat-step label="Taxes" state="taxes">
      <div class="row">
        <div class="col-12">
          <p class="wizard-loan-title">
            {{loan.fullName}}
          </p>
          <h2 class="text-uppercase text-center">
            State Taxes
          </h2>
        </div>
        <div class="col-12 field-explanation" *ngIf="showExplanation">
          Few things are more certain in life than death and state taxes. When purchasing an automobile, you will aways need to consider how much taxes will cost on the vehicle purchase.
          <b>Select the state where you will be purchasing your vehicle.</b>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <mat-form-field appearance="fill" class="wizard-input-length">
            <mat-label>
              State Tax
            </mat-label>
            <mat-select [(ngModel)]="loan.taxRate" name="stateSalesTax" (selectionChange)="selectTaxRate($event.value)">
                <mat-option [value]="0"></mat-option>
                <mat-option *ngFor="let stateRate of stateTaxRates" [value]="stateRate.percent">
                    {{stateRate.label}}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 tax-spacing">
          <!--spacing to match other walkthrough windows-->
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <button mat-raised-button matStepperPrevious class="text-uppercase stage-button">
            <mat-icon>
              chevron_left
            </mat-icon>
            Back
          </button>
        </div>
        <div class="col-6">
          <button mat-raised-button color="primary" matStepperNext class="float-right text-uppercase stage-button">
            Next
            <mat-icon iconPositionEnd>
              east
            </mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <!--Loan Length-->
    <mat-step label="Loan Length" state="loanLength">
      <div class="row">
        <div class="col-12">
          <p class="wizard-loan-title">
            {{loan.fullName}}
          </p>
          <h2 class="text-uppercase text-center">
            Loan Length
          </h2>
        </div>
        <div class="col-12 field-explanation" *ngIf="showExplanation">
          The loan length is the amount of time that it will take you to pay off vehicle purchase if you get a loan. This is usually in months (eg. 12, 24, 36, 48, 60 or even 72 months). The shorter
          the terms of the loan the less money you will pay in interest for your vehicle. <b>Enter the length of your loan in months.</b>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <mat-form-field appearance="outline" class="wizard-input-length">
            <mat-label>
              Loan Length
            </mat-label>
            <input matInput
                   [(ngModel)]="loan.loanLength"
                   (ngModelChange)="loan.recalculate()"
                   type="number"
                   min="0"
                   step="1"
                   inputmode="numeric"
                   placeholder=""
                   name="loanLength"
                   class="wizard-input-text">
            <button mat-icon-button matSuffix color="primary" (click)="toggleExplanation()">
              <mat-icon>
                info
              </mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-slider class="input-slider"
                      color="accent"
                      discrete
                      [max]="84"
                      [min]="0"
                      [step]="1"
                      [showTickMarks]="true">
              <input matSliderThumb (ngModelChange)="loan.recalculate()" [(ngModel)]="loan.loanLength"/>
          </mat-slider>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <button mat-raised-button matStepperPrevious class="text-uppercase stage-button">
            <mat-icon>
              chevron_left
            </mat-icon>
            Back
          </button>
        </div>
        <div class="col-6">
          <button mat-raised-button color="primary" matStepperNext class="float-right text-uppercase stage-button">
            Next
            <mat-icon iconPositionEnd>
              east
            </mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step label="Rate" state="rate">
      <div class="row">
        <div class="col-12">
          <p class="wizard-loan-title">
            {{loan.fullName}}
          </p>
          <h2 class="text-uppercase text-center">
            Rate
          </h2>
        </div>
        <div class="col-12 field-explanation" *ngIf="showExplanation">
          Your cost to borrow money from the bank. If you don't intend to pay cash for your vehicle, you will have to finance through a bank or the dealership. The rate will
          affect the amount of interest you pay to the bank each month and is dependent on your credit score. <b>Enter your rate here.</b>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <mat-form-field appearance="outline" class="wizard-input-length">
            <input matInput
                   [(ngModel)]="loan.rate"
                   (ngModelChange)="loan.recalculate()"
                   inputmode="decimal"
                   type="text"
                   mask="separator.3"
                   separatorLimit="30"
                   placeholder=""
                   name="rate"
                   class="wizard-input-text">
            <span matSuffix>
              %&nbsp;
            </span>
            <button mat-icon-button matSuffix color="primary" (click)="toggleExplanation()">
              <mat-icon>
                info
              </mat-icon>
            </button>
            <mat-hint align="end">
              {{loan.totalInterest | currency}}
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-slider class="input-slider"
                      color="accent"
                      discrete
                      [displayWith]="formatPercent"
                      [max]="30"
                      [min]="0"
                      [step]=".01"
                      [showTickMarks]="true">
            <input matSliderThumb (ngModelChange)="loan.recalculate()" [(ngModel)]="loan.rate" />
          </mat-slider>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <button mat-raised-button matStepperPrevious class="text-uppercase stage-button">
            <mat-icon>
              chevron_left
            </mat-icon>
            Back
          </button>
        </div>
        <div class="col-6">
          <button mat-raised-button color="primary" (click)="closePriceWizard()" class="float-right text-uppercase stage-button">
            Finish
            <mat-icon iconPositionEnd>
              east
            </mat-icon>
          </button>
        </div>
      </div>
    </mat-step>

    <!-- Icon overrides. -->
    <ng-template matStepperIcon="price">
      <mat-icon>
        attach_money
      </mat-icon>
    </ng-template>
    <ng-template matStepperIcon="discount">
      <mat-icon>
        forum
      </mat-icon>
    </ng-template>
    <ng-template matStepperIcon="tradeIn">
      <mat-icon>
        swap_vert
      </mat-icon>
    </ng-template>
    <ng-template matStepperIcon="downPayment">
      <mat-icon>
        forum
      </mat-icon>
    </ng-template>
    <ng-template matStepperIcon="taxes">
      <mat-icon>
        swap_vert
      </mat-icon>
    </ng-template>
    <ng-template matStepperIcon="loanLength">
      <mat-icon>
        forum
      </mat-icon>
    </ng-template>
    <ng-template matStepperIcon="rate">
      <mat-icon>
        forum
      </mat-icon>
    </ng-template>
  </mat-horizontal-stepper>
</mat-dialog-content>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { API_PATH_INJECTABLE, API_PATH_VALUE } from './api-path.constant';
import { CoreRoutingModule } from './core-routing.module';
import { ErrorComponent } from './error/error.component';

@NgModule({
  declarations: [
    ErrorComponent
  ],
  exports: [
    ErrorComponent
  ],
  imports: [
    CommonModule,
    CoreRoutingModule
  ],
  providers: [
    { provide: API_PATH_INJECTABLE, useValue: API_PATH_VALUE }
  ]
})
export class CoreModule { }

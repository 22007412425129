import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { AuthService, LogoutOptions } from '@auth0/auth0-angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, AfterViewInit {

  constructor(public auth: AuthService, @Inject(DOCUMENT) private doc: Document) {

  }

  ngAfterViewInit(): void {
    this.auth.logout({
      async openUrl(url) {
        window.location.replace(url);
      }
    });
  }

  ngOnInit(): void {

  }
}



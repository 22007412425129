import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
/*import { Options, LabelType} from '@angular-slider/ngx-slider';*/
import { Loan } from '../calculator/Loan';

@Component({
  selector: 'financing',
  templateUrl: './financing.component.html',
  styleUrls: ['./financing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FinancingComponent implements OnInit {

  @Input()
  loan: Loan;

  //loanLengthOptions: Options = {
  //  floor: 0,
  //  step: 1,
  //  ceil: 84,
  //  showTicks: true,
  //  //showTicksValues: true,
  //  showSelectionBar: true,
  //  //showTicksValues: true,
  //  ticksArray: [0, 12, 24, 36, 48, 60, 72, 84],
  //  translate: (value: number, label: LabelType): string => {

  //    switch (label) {
  //      case LabelType.Low:
  //        return value.toString();
  //      case LabelType.High:
  //        return value.toString();
  //      default:
  //        return '';
  //    }
  //  },
  //};

  //loanRateOptions: Options = {
  //  floor: 0,
  //  step: .01,
  //  ceil: 30,
  //  //showTicks: true,
  //  showTicksValues: true,
  //  showSelectionBar: true,
  //  ticksArray: [0, 5, 10, 15, 20, 25, 30],
  //  translate: (value: number, label: LabelType): string => {

  //    switch (label) {
  //      case LabelType.Low:
  //        return `${Math.floor(value)}%`;
  //      case LabelType.High:
  //        return `${Math.floor(value)}%`;
  //      default:
  //        return '';
  //    }
  //  },
  //};

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    const newLoanData: Loan = <Loan><unknown>(changes.loan.currentValue);
    this.loan = newLoanData;
  }

  /**
   * format percent values
   * @param value
   */
  formatPercent(value: number) {
    return `${value}%`;
  }

}

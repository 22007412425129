import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IdentityRoutingModule } from './identity-routing.module';
import { LoginButtonComponent } from './login-button/login-button.component';
import { LogoutButtonComponent } from './logout-button/logout-button.component';
import { LogoutComponent } from './logout/logout.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupButtonComponent } from './signup-button/signup-button.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from '../core/core.module';

@NgModule({
  declarations: [
    LogoutComponent,
    LoginButtonComponent,
    LogoutButtonComponent,
    SignupButtonComponent,
    ProfileComponent,
    LoginComponent
  ],
  imports: [
    CoreModule,
    HttpClientModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    IdentityRoutingModule
  ],
  exports: [
    LogoutComponent,
    LoginButtonComponent,
    LogoutButtonComponent,
    SignupButtonComponent,
    ProfileComponent
  ],
})
export class IdentityModule { }

<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer
                 class="sidenav"
                 fixedInViewport
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'">
        <mat-toolbar>
            CalcMyRide
        </mat-toolbar>
        <mat-nav-list>
            <a mat-list-item routerLink="/" routerLinkActive="active" (click)="drawer.close()">
                Home
            </a>
            <!--<a mat-list-item routerLink="/quick-calc" routerLinkActive="active" (click)="drawer.close()">
              Quick Calc
            </a>-->
            <a mat-list-item routerLink="/" routerLinkActive="active" (click)="drawer.close()">
                Quick Calc
            </a>
            <a mat-list-item routerLink="/calculator" routerLinkActive="active" (click)="drawer.close()">
                Advanced
            </a>
            <a mat-list-item routerLink="/about" routerLinkActive="active" (click)="drawer.close()">
                About
            </a>
            <a mat-list-item routerLink="/support" routerLinkActive="active" (click)="drawer.close()">
                support
            </a>
            <!--<app-login-button *ngIf="(auth.isAuthenticated$ | async) == false"></app-login-button>
    <app-logout-button *ngIf="(auth.isAuthenticated$ | async) == true"></app-logout-button>
    <a mat-list-item routerLink="/profile" routerLinkActive="(auth.isAuthenticated$ | async)" (click)="drawer.close()">
      <mat-icon class="icon-color">person</mat-icon>
    </a>-->
            <!--<a mat-list-item routerLink="/login" routerLinkActive="active" (click)="drawer.close()">
      Login
    </a>
    <a mat-list-item routerLink="/profile" routerLinkActive="active" (click)="drawer.close()">
      Profile
    </a>-->
            <!--<a mat-list-item routerLink="/financing" routerLinkActive="active">
      Financing
    </a>
    <a mat-list-item routerLink="/find-my-car" routerLinkActive="active">
      Find My Car
    </a>
    <a mat-list-item routerLink="/login" routerLinkActive="active">
      Login
    </a>
    <a mat-list-item routerLink="/profile" routerLinkActive="active">
      Profile
    </a>-->
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
        <mat-toolbar color="primary">
            <button type="button"
                    class="pull-left"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    (click)="drawer.toggle()"
                    *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">
                    menu
                </mat-icon>
            </button>
            <!--<div class="app-title">-->
            <span>
                <a routerLink="/home" routerLinkActive="active">
                    CalcMyRide
                </a>
            </span>

            <span class="spacer" *ngIf="!(isHandset$ | async)"></span>
            <span class="spacer" *ngIf="!(isHandset$ | async)"></span>
            <!--<span *ngIf="!(isHandset$ | async)">
              <a routerLink="/quick-calc" routerLinkActive="active">
                Quick Calc
              </a>
            </span>-->
            <span *ngIf="!(isHandset$ | async)">
                <a routerLink="/home" routerLinkActive="active">
                    Quick Calc
                </a>
            </span>
            <span *ngIf="!(isHandset$ | async)">
                <a routerLink="/calculator" routerLinkActive="active">
                    Advanced
                </a>
            </span>
            <span *ngIf="!(isHandset$ | async)">
                <a routerLink="/about" routerLinkActive="active">
                    About
                </a>
            </span>
            <span *ngIf="!(isHandset$ | async)">
                <a routerLink="/support" routerLinkActive="active">
                    Support
                </a>
            </span>
            <!--<span *ngIf="!(isHandset$ | async)">
      <app-login-button *ngIf="(auth.isAuthenticated$ | async) == false"></app-login-button>
      <app-logout-button *ngIf="(auth.isAuthenticated$ | async) == true"></app-logout-button>
    </span>
    <span *ngIf="!(isHandset$ | async)">
      <a routerLink="/profile" routerLinkActive="(auth.isAuthenticated$ | async)" (click)="drawer.close()">
        <mat-icon class="icon-color">person</mat-icon>
      </a>
    </span>-->
        </mat-toolbar>
        <ng-content>
            <!--This is where our router places our content or where the router routes our content-->
        </ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>

<div class="row">
    <div class="col-6">
        <label class="float-left text-uppercase pricing-label">
            Price
        </label>
    </div>
    <div class="col-6">
        <mat-form-field appearance="outline" class="float-right pricing-input">
            <input matInput
                   [(ngModel)]="loan.price"
                   (ngModelChange)="updateLoan()"
                   type="text"
                   mask="separator"
                   thousandSeparator=","
                   separatorLimit="9999999"
                   inputmode="numeric"
                   placeholder=""
                   name="price">
            <span matPrefix>
                &nbsp;$
            </span>
        </mat-form-field>
    </div>
    <div class="col-12 carculator-slider">
        <!--<ngx-slider [(ngModel)]="loan.price" [options]="priceSliderOptions" (userChange)="updateLoan()" name="priceSlider"></ngx-slider>-->
    </div>
</div>

<!--<div class="row">
  <div class="col-6">
    <label class="float-left text-uppercase pricing-label">
      Price
    </label>
  </div>
  <div class="col-6">
    <mat-form-field appearance="outline" class="float-right pricing-input">
      <input matInput
             [(ngModel)]="loan.price"
             (ngModelChange)="loan.recalculate()"
             type="text"
             mask="separator"
             thousandSeparator=","
             separatorLimit="9999999"
             inputmode="numeric"
             placeholder=""
             name="salesPrice">
      <span matPrefix>
        &nbsp;$
      </span>
    </mat-form-field>
  </div>
  <div class="col-12">
    <mat-slider class="input-slider"
                thumbLabel
                [displayWith]="formatThousands"
                [invert]="false"
                [max]="999999"
                [min]="0"
                [step]="1000"
                [tickInterval]="1000"
                [(ngModel)]="loan.price"
                (ngModelChange)="loan.recalculate()"
                [vertical]="false"
                name="salesPriceSlider">

    </mat-slider>
  </div>
</div>-->
<div class="row">
    <div class="col-6">
        <label class="float-left text-uppercase pricing-label">
            Discount
        </label>
    </div>
    <div class="col-6">
        <mat-form-field appearance="outline" class="float-right pricing-input  mt-3">
            <input matInput [(ngModel)]="loan.discount"
                   (ngModelChange)="loan.recalculate()"
                   type="text"
                   min="0"
                   step="100"
                   mask="separator"
                   thousandSeparator=","
                   separatorLimit="9999999"
                   inputmode="numeric"
                   placeholder=""
                   name="discount">
            <span matPrefix>
                &nbsp;$
            </span>
        </mat-form-field>
    </div>
    <div class="col-12 carculator-slider">
        <!--        <mat-slider class="input-slider"
                            thumbLabel
                            [displayWith]="formatThousands"
                            [invert]="false"
                            [max]="loan.price"
                            [min]="0"
                            [step]="1000"
                            [thumbLabel]="true"
                            [(ngModel)]="loan.discount"
                            (ngModelChange)="loan.recalculate()"
                            [vertical]="false"
                            name="discountSlider">
                </mat-slider>-->
        <!--[highValue]="20000"-->
        <!--<ngx-slider [(ngModel)]="loan.discount" [options]="discountSliderOptions" (userChange)="updateLoan()" name="discountSlider"></ngx-slider>-->
    </div>
</div>

<div class="row">
    <div class="col-6">
        <label class="float-left text-uppercase pricing-label">
            Trade-In
        </label>
    </div>
    <div class="col-6">
        <mat-form-field appearance="outline" class="float-right pricing-input mt-3">
            <input matInput [(ngModel)]="loan.tradeIn"
                   (ngModelChange)="loan.recalculate()"
                   type="text"
                   min="0"
                   step="100"
                   mask="separator"
                   thousandSeparator=","
                   separatorLimit="9999999"
                   inputmode="numeric"
                   placeholder=""
                   name="tradeIn">
            <span matPrefix>
                &nbsp;$
            </span>
        </mat-form-field>
    </div>
    <div class="col-12 carculator-slider">
        <!--       <mat-slider class="input-slider"
                           thumbLabel
                           [displayWith]="formatThousands"
                           [invert]="false"
                           [max]="100000"
                           [min]="0"
                           [step]="1000"
                           [tickInterval]="1000"
                           [(ngModel)]="loan.tradeIn"
                           (ngModelChange)="loan.recalculate()"
                           [vertical]="false"
                           name="tradeInSlider">
               </mat-slider>-->
        <!-- <ngx-slider [(ngModel)]="loan.tradeIn" [options]="tradeInSliderOptions" (userChange)="updateLoan()" name="tradeInSlider"></ngx-slider>-->
    </div>
</div>

<div class="row">
    <div class="col-6">
        <label class="float-left text-uppercase pricing-label">
            Down Payment
        </label>
    </div>
    <div class="col-6">
        <mat-form-field appearance="outline" class="float-right pricing-input mt-3">
            <input matInput [(ngModel)]="loan.downPayment"
                   (ngModelChange)="loan.recalculate()"
                   min="0"
                   step="100"
                   type="text"
                   mask="separator"
                   thousandSeparator=","
                   separatorLimit="9999999"
                   inputmode="numeric"
                   placeholder="" name="downPayment">
            <span matPrefix>
                &nbsp;$
            </span>
        </mat-form-field>
    </div>
    <div class="col-12 carculator-slider">
        <!--        <mat-slider class="input-slider"
                          thumbLabel
                          [displayWith]="formatThousands"
                          [invert]="false"
                          [max]="loan.price"
                          [min]="0"
                          [step]="1000"
                          [tickInterval]="1000"
                          [(ngModel)]="loan.downPayment"
                          (ngModelChange)="loan.recalculate()"
                          [vertical]="false"
                          name="downPaymentSlider">
                </mat-slider>-->
        <!-- <ngx-slider [(ngModel)]="loan.downPayment" [options]="downPaymentSliderOptions" (userChange)="updateLoan()" name="downPaymentSlider"></ngx-slider>-->
    </div>
</div>
<div class="row">
    <div class="col">
        <label class="float-left text-uppercase pricing-label">
            State Tax
        </label>
    </div>
    <div class="col">
        <mat-form-field appearance="fill" class="float-right">
            <mat-select [(ngModel)]="loan.taxRate" name="stateSalesTax" (selectionChange)="selectTaxRate($event.value)">
                <mat-option [value]="0"></mat-option>
                <mat-option *ngFor="let stateRate of stateTaxRates" [value]="stateRate.percent">
                    {{stateRate.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <br />
        <br />
        <br />
        <br />
        <br />
    </div>
</div>

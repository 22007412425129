import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Loan } from '../calculator/Loan';
import { LoanSummaryComponent } from '../loan-summary/loan-summary.component';
import { LoanUrlService } from '../loan-url.service';
import { PricingService } from '../pricing.service';
import { TaxRate } from '../pricing/TaxRate';
import { ShareButtonComponent } from '../share-button/share-button.component';

@Component({
  selector: 'app-quick-calc',
  templateUrl: './quick-calc.component.html',
  styleUrls: ['./quick-calc.component.scss']
})
export class QuickCalcComponent implements OnInit {

  @ViewChild('loanSummary')
  loanSummary: LoanSummaryComponent;

  @ViewChild('shareButtonCalc')
  shareButtonCalc: ShareButtonComponent;

  @ViewChild('shareButtonReview')
  shareButtonReview: ShareButtonComponent;

  loan: Loan;
  showExplanation: boolean = false;
  stateTaxRates: TaxRate[];

  quickCalUrl: string;

  constructor(private activeRoute: ActivatedRoute,
    private router: Router,
    private pricingService: PricingService,
    private loanUrlService: LoanUrlService,
    private ref: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.quickCalUrl = window.location.href.split('?')[0];

    this.loan = this.loanUrlService.extractLoanQueryParamsOrHistoryState(this.activeRoute.snapshot, history.state)

    this.stateTaxRates = this.pricingService.generateTaxRates();
  }

  goToAdvanced() {
    this.router.navigateByUrl('calculator', { state: this.loan })
  }

  selectTaxRate(value: number) {
    this.loan.updateTaxSelection(value);
    this.ref.markForCheck();
  }
}

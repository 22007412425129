<div class="container">
    <div class="row mt-2">
        <div class="col-12">
            <p>Welcome to the Calc My Ride Playground</p>

            <h3>Standard Input</h3>
            <input [(ngModel)]="price" type="number" inputmode="numeric" placeholder="Enter a price..." name="price">
            <br />
            Price Output: {{price}}

            <h3>Material Input</h3>
            <mat-form-field appearance="outline" class="mt-3">
                <mat-label>
                    Material Price
                </mat-label>
                <input matInput
                       [(ngModel)]="price"
                       (ngModelChange)="changesMethod()"
                       type="text"
                       inputmode="numeric"
                       placeholder=""
                       name="salesPrice">
                <span matPrefix>
                    &nbsp;$
                </span>
            </mat-form-field>
        </div>
    </div>
</div>


/*/*import { domain, clientId, audience, serverUrl } from '../../auth_config.json';*/


//"domain": "dev-oea74--3.us.auth0.com",
//  "clientId": "LvOUDfnNC2Uq27GQqLtCy6EhqBVfv9Sj",
//    "audience": "https://calcmyride.com",
//      "serverUrl": "http://localhost:44474"

const domain = "dev-oea74--3.us.auth0.com";
const clientId = "LvOUDfnNC2Uq27GQqLtCy6EhqBVfv9Sj";
const audience = "https://calcmyride.com";
const serverUrl = "http://localhost:44474";

export const environment = {
  production: false,
  auth: {
    domain,
    clientId,
    redirectUri: `${window.location.origin}/login`,
    audience,
  },
  dev: {
    serverUrl,
  },
  API_PATH: "https://localhost:7225"
};

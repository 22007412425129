<div class="container">
  <div class="row mt-4">
    <div class="col-12">
      <h1 class="text-center">
        Welcome to the CalcMyRide App!
      </h1>
      <p class="text-center">
        Easily calculate and compare the true price of a vehicle purchase across multiple financing scenarios.
      </p>
      <p class="text-center">
        Let's Get Started!
      </p>
    </div>
  </div>
  <div class="row go-container">
    <div class="col-12 text-center">
      <button mat-fab color="primary" class="go-button" (click)="goToQuickCalc()">
        Let's Get Started!
      </button>
    </div>
  </div>
  <div class="row">
    <google-adsense></google-adsense>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { LandingPageRoutingModule } from './landing-page-routing.module';
import { MatCardModule } from '@angular/material/card';
import { GoogleAdsenseModule } from '../google-adsense/google-adsense.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    AboutComponent,
    HomeComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    GoogleAdsenseModule,
    LandingPageRoutingModule
  ],
  exports: [
    AboutComponent,
    HomeComponent
  ]
})
export class LandingPageModule { }

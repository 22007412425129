/**
 * State tax rates
 * */
export class TaxRate {

    state: string;
    abbreviation: string;
    label: string;
    rate: number;
    percent: number;

    constructor(state: string, abbreviation: string, rate: number) {
        this.state = state;
        this.abbreviation = abbreviation;
        this.rate = rate;
        this.percent = this.rateAsPercent();
        this.label = `${this.state} - ${this.percent}%`;
    }

    private rateAsPercent() {
        return this.rate / 100;
    }
}

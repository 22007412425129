<div class="container overflow-auto container-height">
    <div class="row mt-4">
        <div class="col-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        Support
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    Thank you for using CalcMyRide. We hope you are enjoying the app and finding value in calculating the true price of an automobile purchase.
                    For any and all questions, comments, bugs or feature requests, please email <a href="">support@calcmyride.com</a>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>

import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss']
})
export class PlaygroundComponent implements OnInit, OnChanges {

  public price: number;

  ngOnInit(): void {
    //throw new Error('Method not implemented.');
    console.log("ngOnInit() called...");
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("Ng Changes Called...");
    //throw new Error('Method not implemented.');
  }

  changesMethod(): void {
    console.log("ChangesMethod called...")
  }

}

<div class="row">
  <div class="col-6">
    <label class="float-left text-uppercase pricing-label">
      Loan Length
    </label>
  </div>
  <div class="col-6">
    <mat-form-field appearance="outline" class="float-right pricing-input">
      <input matInput [(ngModel)]="loan.loanLength" (ngModelChange)="loan.recalculate()" type="number" min="0" step="1" inputmode="numeric" placeholder="" name="loanLength">
    </mat-form-field>
  </div>
  <div class="col-12 carculator-slider">
<!--    <mat-slider class="input-slider"
                thumbLabel
                [invert]="false"
                [max]="84"
                [min]="0"
                [step]="1"
                [tickInterval]="1"
                [(ngModel)]="loan.loanLength"
                (ngModelChange)="loan.recalculate()"
                [vertical]="false"
                name="loanLengthSlider">
    </mat-slider>-->

   <!-- <ngx-slider [(value)]="loan.loanLength" [options]="loanLengthOptions" (valueChange)="loan.recalculate()"></ngx-slider>-->
  </div>
</div>
<div class="row">
  <div class="col-6">
    <label class="float-left text-uppercase pricing-label">
      Rate
    </label>
  </div>
  <div class="col-6">
    <mat-form-field appearance="outline" class="float-right pricing-input tax-percent">
      <input matInput
             [(ngModel)]="loan.rate"
             (ngModelChange)="loan.recalculate()"
             inputmode="decimal"
             type="text"
             mask="separator.3"
             separatorLimit="30"
             placeholder=""
             name="rate">
      <span matSuffix>
        %&nbsp;
      </span>
      <mat-hint align="end">
        {{loan.totalInterest | currency}}
      </mat-hint>
    </mat-form-field>
  </div>
  <div class="col-12 carculator-slider">
<!--    <mat-slider class="input-slider"
                thumbLabel
                [displayWith]="formatPercent"
                [invert]="false"
                [max]="30"
                [min]="0"
                [step]=".01"
                [tickInterval]=".001"
                [(ngModel)]="loan.rate"
                (ngModelChange)="loan.recalculate()"
                [vertical]="false"
                name="rateSlider">
    </mat-slider>-->

    <!--<ngx-slider [(value)]="loan.rate" [options]="loanRateOptions" (valueChange)="loan.recalculate()"></ngx-slider>-->
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { ApiAuthorizationModule } from '../../api-authorization/api-authorization.module';
import { IdentityModule } from '../identity/identity.module';
import { MainNavComponent } from './main-nav/main-nav.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';

@NgModule({
  declarations: [
    MainNavComponent,
    NavMenuComponent,
  ],
  imports: [
    //ApiAuthorizationModule,
    IdentityModule,
    CommonModule,
    MatButtonModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    RouterModule
  ],
  exports: [
    MainNavComponent,
    NavMenuComponent
  ]
})
export class LayoutModule { }

<div class="row">
  <div class="col-4">
    <label class="float-left text-uppercase pricing-label">
      Total
    </label>
  </div>
  <div class="col-8">
    <mat-form-field class="float-right total-purchase-cost summary-field">
      <input matInput value="{{loan.totalCost | currency}}" placeholder="" name="totalCost" readonly>
      <mat-hint align="end">
        Price + Interest + Tax
      </mat-hint>
    </mat-form-field>
  </div>
</div>

<!--<div class="row">
  <div class="col-6">
    <button mat-raised-button class="action-button text-uppercase" color="accent">
      Get Financing
    </button>
  </div>
  <div class="col-6">
    <button mat-raised-button class="action-button text-uppercase" color="accent">
      Buy Vehicle
    </button>
  </div>
</div>-->

<div class="row mt-5 summary-footer-padding">
  <div class="col-12">
    <h3 class="text-center">
      Purchase Breakdown
    </h3>
  </div>
  <div class="col-12">
    <mat-accordion class="example-headers-align" multi>
      <mat-expansion-panel class="details-summary-html2canvas" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Details
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row">
          <div class="col-4">
            <label class="loat-left text-uppercase pricing-label">
              Total
            </label>
          </div>
          <div class="col-8">
            <mat-form-field class="float-right total-financed summary-field">
              <input matInput value="{{loan.totalCost | currency}}" placeholder="" name="total" readonly>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <label class="loat-left text-uppercase pricing-label">
              Price
            </label>
          </div>
          <div class="col-8">
            <mat-form-field class="float-right total-financed summary-field">
              <input matInput value="{{loan.price | currency}}" placeholder="" name="price" readonly>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <label class="loat-left text-uppercase pricing-label">
              Financed
            </label>
          </div>
          <div class="col-8">
            <mat-form-field class="float-right total-financed summary-field">
              <input matInput value="{{loan.totalFinanced | currency}}" placeholder="" name="totalFinanced" readonly>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <label class="loat-left text-uppercase pricing-label">
              Rate
            </label>
          </div>
          <div class="col-8">
            <mat-form-field class="float-right total-financed summary-field">
              <input matInput value="{{ (loan.rate / 100) | percent }}" placeholder="" name="totalFinanced" readonly>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <label class="float-left text-uppercase pricing-label">
              Interest
            </label>
          </div>
          <div class="col-8">
            <mat-form-field class="float-right total-loan-cost summary-field">
              <input matInput value="{{loan.totalInterest | currency}}" placeholder="" name="costOfLoan" readonly>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <label class="float-left text-uppercase pricing-label">
              Payment / MTH
            </label>
          </div>
          <div class="col-8">
            <mat-form-field class="float-right monthly-payment summary-field">
              <input matInput value="{{loan.monthlyPayment | currency}}" placeholder="" name="monthlyPayment" readonly>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label class="loat-left text-uppercase pricing-label">
              Tax Rate
            </label>
          </div>
          <div class="col-6">
            <mat-form-field class="float-right total-financed summary-field">
              <input matInput value="{{ (loan.taxRate / 100) | percent}}" placeholder="" name="stateSalesTax" readonly>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <label class="loat-left text-uppercase pricing-label">
              Tax
            </label>
          </div>
          <div class="col-8">
            <mat-form-field class="float-right total-financed summary-field">
              <input matInput value="{{loan.salesTax | currency}}" placeholder="" name="stateSalesTax" readonly>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button mat-raised-button class="action-button text-uppercase" (click)="saveDetailSummary()" color="accent">
              Save Details
            </button>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Pricing Chart
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <summary-bar-chart [price]="loan.price" [totalInterest]="loan.totalInterest" [salesTax]="loan.salesTax"></summary-bar-chart>
        <!--do we need to add a panel button here to download?-->
      </mat-expansion-panel>
      <mat-expansion-panel class="details-timeline-html2canvas" [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Amoritization Timeline
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <summary-graph [cumulativeBalanceSeries]="loan.cumulativeBalanceSeries"
                       [cumulativeInterestSeries]="loan.cumulativeInterestSeries"
                       [cumulativePrincipalSeries]="loan.cumulativePrincipalSeries"
                       [xAxisPeriodSeries]="loan.xAxisPeriodSeries"></summary-graph>
        <div class="row">
          <div class="col-12">
            <button mat-raised-button class="action-button text-uppercase" (click)="saveDetailTimeline()" color="accent">
              Save Summary Timeline
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

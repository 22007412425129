import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AmoritizationScheduleComponent } from '../amoritization-schedule/amoritization-schedule.component';
import { Loan } from '../calculator/Loan';
import { LoanSummaryComponent } from '../loan-summary/loan-summary.component';
import { PricingComponent } from '../pricing/pricing.component';

@Component({
  selector: 'loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss']
})
export class LoanComponent implements OnInit {

  @Input()
  loan: Loan;

  @ViewChild('loanPricing')
  loanPricing: PricingComponent;

  @ViewChild('loanPricing')
  loanFinancing: PricingComponent;

  @ViewChild('loanSummary')
  loanSummary: LoanSummaryComponent;

  @ViewChild('loanSchedule')
  loanASchedule: AmoritizationScheduleComponent;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    const newLoanData: Loan = <Loan><unknown>(changes.loan.currentValue);
    this.loan = newLoanData;
  }

}

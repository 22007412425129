<div class="container">
    <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" (selectedTabChange)="onTabChanged($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <div>
                    <div>
                        {{loan.fullName}}
                    </div>
                    <div class="calculator-form-tab-text">
                        {{loan.totalCost | currency}}
                    </div>
                </div>
            </ng-template>
            <ng-template matTabContent>
                <loan [loan]="loan" #loanAForm>
                </loan>
            </ng-template>
        </mat-tab>
        <mat-tab class="calculator-form-tab" label="Loan B">
            <ng-template mat-tab-label>
                <div>
                    <div>
                        {{loanB.fullName}}
                    </div>
                    <div class="calculator-form-tab-text">
                        {{loanB.totalCost | currency}}
                    </div>
                </div>
            </ng-template>
            <ng-template matTabContent>
                <loan [loan]="loanB" #loanBForm>
                </loan>
            </ng-template>
        </mat-tab>
        <mat-tab class="calculator-form-tab" label="Loan C">
            <ng-template mat-tab-label>
                <div>
                    <div>
                        {{loanC.fullName}}
                    </div>
                    <div class="calculator-form-tab-text">
                        {{loanC.totalCost | currency}}
                    </div>
                </div>
            </ng-template>
            <ng-template matTabContent>
                <loan [loan]="loanC" #loanCForm>
                </loan>
            </ng-template>
        </mat-tab>
        <!--<mat-tab label="Comparison">
          Comparison
        </mat-tab>-->
    </mat-tab-group>
    <button mat-fab [matMenuTriggerFor]="aboveMenu" class="fab-bottom-right">
        <mat-icon>
            directions_car
        </mat-icon>
    </button>
    <mat-menu #aboveMenu="matMenu" yPosition="above">
        <button mat-menu-item (click)="goToQuickCalc()">
            <mat-icon>
                fast_forward
            </mat-icon>
            <span>Quick Calc</span>
        </button>
        <button mat-menu-item (click)="openPriceWizard(currentWizardLoan)">
            <mat-icon>
                live_help
            </mat-icon>
            <span>
                Price Wizard
            </span>
        </button>
<!--        <button mat-menu-item>
            <!-- <share-button [loanAsQueryParameters]="loan.loanAsQueryParameters" [pageUrl]="quickCalUrl" #shareButton></share-button>
            <mat-icon>
                share
            </mat-icon>
            <span>Share</span>
        </button>-->
        <!--<button mat-menu-item>
      Rename Loan
      <mat-icon class="float-right fab-menu-item-icon">
        edit
      </mat-icon>
    </button>
    <button mat-menu-item>
      Shop Vehicles
      <mat-icon class="float-right fab-menu-item-icon">
        car_rental
      </mat-icon>
    </button>
    <button mat-menu-item>
      Acquire Financing
      <mat-icon class="float-right fab-menu-item-icon">
        money
      </mat-icon>
    </button>-->
    </mat-menu>
</div>

import { Period } from "./Period";

/**
 * Loan class representing a new car loan
 * */
export class Loan {

  amoritizationSchedule: Period[];

  private firstView: boolean = false;

  setFirstView(): void {
    this.firstView = true;
  }

  /**
   * Are we on the first view of the loan? If so, likely show the wizard dialog.
   */
  isFirstView(): boolean {
    return this.firstView;
  }

  /**
   * do we need to show the loan wizard?
   */
  showWizard: boolean;

  /**
   * name of the loan
   */
  name: string;

  fullName: string;

  /**
   * State
   */
  taxRate: number;

  /**
   * Price of vehicle
   */
  price: number;

  /**
   * Total sales tax
   */
  salesTax: number;

  calculateSalesTax() {
    if (this.taxRate >= 0) {
      this.salesTax = (this.price - this.tradeIn - this.discount) * (this.taxRate / 100);
    } else {
      this.salesTax = this.price;
    }
  }

  /**
   * Discount from price
   */
  discount: number = 0

  /**
   * Trade in value
   */
  tradeIn: number = 0;

  /**
   * Down payment
   */
  downPayment: number = 0;

  /**
   * Total amount borrowed
   */
  totalFinanced: number;

  private calculateTotalFinanced(): void {
    const equity = this.downPayment + this.tradeIn + this.discount;

    this.totalFinanced = this.price - equity;
  }

  /**
   * Lenth of loans in terms of months
   */
  loanLength: number;

  /**
   * Interest rate
   */
  rate: number;

  /**
   * Total interest payment
   */
  totalInterest: number;
  private calcTotalInterest(): void {

    const lastPeriod = this.lastPeriod();

    if (lastPeriod) {
      let length = this.amoritizationSchedule?.length;

      let lastPeriod = this.amoritizationSchedule[length - 1];
      this.totalInterest = lastPeriod.cumulativeInterest;
    } else {
      this.totalInterest = 0;
    }
  }

  /**
   * Get the last period of the amoritzation schedule
   * */
  private lastPeriod(): Period {

    let lastPeriod: Period = null;

    if (this.amoritizationSchedule?.length > 0) {
      let length = this.amoritizationSchedule?.length;

      lastPeriod = this.amoritizationSchedule[length - 1];
    }

    return lastPeriod;
  }

  /**
   * Per month cost of loan
   */
  monthlyPayment: number;

  private calculateMonthlyPayment(): void {

    const numerator = this.totalFinanced * (this.rateAsPercent / this.periodLength);
    const denomenator = 1 - (Math.pow(1 + (this.rateAsPercent / 12), this.loanLength * -1));

    this.monthlyPayment = numerator / denomenator;
  }

  get rateAsPercent(): number {
    return this.rate / 100;
  }

  periodLength: number = 12;

  /**
   * Total cost of loan including interest
   */
  costOfLoan: number;

  /**
   * Total cost of car
   */
  totalCost: number;

  /**
   * the current loan saved as query string parameters
   * */
  loanAsQueryParameters: string;

  /**
   * x axes periods
   * */
  xAxisPeriodSeries: string[];

  /**
   * cumulative principal series
   * */
  cumulativePrincipalSeries: number[];

  /**
   * running aggregation of the cumulative interest
   * */
  cumulativeInterestSeries: number[];

  /**
   * running aggregation of the cumulative balance
   * */
  cumulativeBalanceSeries: number[];

  /**
   * recalculate the amoritization schedule. Must be done in this order
   * */
  recalculate() {
    this.calculateTotalFinanced();
    this.calculateMonthlyPayment();
    this.calculateSalesTax();
    this.buildAmoritizationSchedule();
    this.calcTotalInterest();
    this.calcTotalCost();
    this.buildUrl();
    this.buildSeriesData();
  }

  buildSeriesData() {
    this.xAxisPeriodSeries = this.xAxisPeriodSeriesGeneration();
    this.cumulativeBalanceSeries = this.cumulativeBalanceSeriesGeneration();
    this.cumulativeInterestSeries = this.cumulativeInterestSeriesGeneration();
    this.cumulativePrincipalSeries = this.cumulativePrincipalSeriesGeneration();
  }

  /**
   * Update the selection of a tax rate
   * */
  updateTaxSelection(value: number) {

    if (value !== undefined) {
      this.taxRate = value;
      this.recalculate();
    }
  }

  calcTotalCost() {
    this.totalCost = this.totalFinanced + this.totalInterest + this.salesTax;
  }

  buildUrl() {

    // name, price, stateTaxRate, loanLength, loanRate
    // startDate

    const concatUrl: (currentUrl: string, parameterName: string, value: string | number | Date, skipAmpersand: boolean) =>
      string = (currentUrl: string, parameterName: string, value: string | number | Date, skipAmpersand: boolean) => {

        const valueAsString: string = value.toString();

        const encodedValue = encodeURIComponent(valueAsString);

        let newUrl = ""

        if (skipAmpersand !== true) {
          newUrl = `${currentUrl}&${parameterName}=${encodedValue}`;
        } else {
          newUrl = `${currentUrl}${parameterName}=${encodedValue}`;
        }

        return newUrl
      }

    this.loanAsQueryParameters = "";

    this.loanAsQueryParameters = concatUrl(this.loanAsQueryParameters, "discount", this.discount, true);
    this.loanAsQueryParameters = concatUrl(this.loanAsQueryParameters, "tradeIn", this.tradeIn, false);
    this.loanAsQueryParameters = concatUrl(this.loanAsQueryParameters, "downPayment", this.downPayment, false);
    this.loanAsQueryParameters = concatUrl(this.loanAsQueryParameters, "name", this.name, false);

    this.loanAsQueryParameters = concatUrl(this.loanAsQueryParameters, "loanLength", this.loanLength, false);
    this.loanAsQueryParameters = concatUrl(this.loanAsQueryParameters, "rate", this.rate, false);
    this.loanAsQueryParameters = concatUrl(this.loanAsQueryParameters, "price", this.price, false);
    this.loanAsQueryParameters = concatUrl(this.loanAsQueryParameters, "taxRate", this.taxRate, false);
    this.loanAsQueryParameters = concatUrl(this.loanAsQueryParameters, "startDate", this.startDate, false);
  }

  buildAmoritizationSchedule(): void {
    this.amoritizationSchedule = [];

    let firstPeriod = new Period(this.monthlyPayment,
      this.totalFinanced,
      1,
      this.startDate,
      this.rateAsPercent,
      this.periodLength, 0, 0);

    this.amoritizationSchedule.push(firstPeriod);

    let nextPeriod = firstPeriod;

    while (nextPeriod.endingBalance > 0) {

      nextPeriod = new Period(this.monthlyPayment,
        nextPeriod.endingBalance,
        nextPeriod.nextId(),
        nextPeriod.nextDate(),
        this.rateAsPercent,
        this.periodLength,
        nextPeriod.cumulativePrincipal,
        nextPeriod.cumulativeInterest);

      this.amoritizationSchedule.push(nextPeriod);
    }

  }

  /**
   * the start date of the loan
   */
  startDate: Date;

  constructor(name: string,
    price: number,
    stateTaxRate: number,
    loanLength: number = 36,
    loanRate: number = 5.00,
    downPayment: number = 0,
    discount: number = 0,
    tradeIn: number = 0,
    startDate: Date = undefined) {

    this.name = name;
    this.fullName = `Car ${name}`;
    this.price = price;
    this.taxRate = stateTaxRate;
    this.loanLength = loanLength;
    this.rate = loanRate;
    this.downPayment = downPayment;
    this.discount = discount;
    this.tradeIn = tradeIn;

    if (startDate === undefined) {
      this.startDate = new Date();
    } else {
      this.startDate = startDate;
    }

    this.startDate.setMonth(this.startDate.getMonth() + 1, 1);

    this.amoritizationSchedule = [];

    this.recalculate();
  }

  /**
  * convert periods to x axis labels
  * */
  private xAxisPeriodSeriesGeneration(): string[] {

    const periodStarts: string[] = [];

    this.amoritizationSchedule.forEach(period => {

      let xAxisLabel: string;
      xAxisLabel = period
        .date
        .toLocaleString('default', { month: 'short' })
        .toLocaleUpperCase();

      const year = period
        .date
        .getFullYear()
        .toString()
        .substr(2, 2);

      xAxisLabel = `${xAxisLabel} ${year}`;

      periodStarts.push(xAxisLabel);
    });

    return periodStarts;
  }

  /**
   * cumulative principal series data
   * */
  cumulativePrincipalSeriesGeneration(): number[] {
    return this.amoritizationSchedule.map((value) => {

      if (value?.cumulativePrincipal >= 0) {
        return value.cumulativePrincipal;
      }

      return 0;
    });
  }

  /**
   * cumulative interest series data
   * */
  cumulativeInterestSeriesGeneration(): number[] {
    return this.amoritizationSchedule.map((value) => {

      if (value?.cumulativeInterest >= 0) {
        return value.cumulativeInterest;
      }

      return 0
    });
  }

  /**
   * cumulative balance series data
   * */
  cumulativeBalanceSeriesGeneration(): number[] {
    return this.amoritizationSchedule.map((value) => {

      if (value?.endingBalance >= 0) {
        return value.endingBalance;
      }

      return 0;
    });
  }
}

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { AmoritizationScheduleComponent } from '../amoritization-schedule/amoritization-schedule.component';
import { LoanUrlService } from '../loan-url.service';
import { LoanComponent } from '../loan/loan.component';
import { PriceWizardComponent } from '../price-wizard/price-wizard.component';
import { ShareButtonComponent } from '../share-button/share-button.component';
import { Loan } from './Loan';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalculatorComponent implements OnInit, OnChanges {

  @ViewChild('loanSchedule')
  loanASchedule: AmoritizationScheduleComponent;

  @ViewChild('loanAForm')
  loanAForm: LoanComponent;

  @ViewChild('loanBForm')
  loanBForm: LoanComponent;

  @ViewChild('loanCForm')
  loanCForm: LoanComponent;

  //@ViewChild('shareButton')
  //shareButton: ShareButtonComponent;

  primaryLoan: Loan;
  secondaryLoan: Loan;
  tertiaryLoan: Loan;

  loan: Loan;
  loanB: Loan;
  loanC: Loan;

  currentWizardLoan: Loan;

  quickCalUrl: string;

  constructor(public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private loanUrlService: LoanUrlService,
    private activeRoute: ActivatedRoute,
    private router: Router ) {

  }

  ngOnInit(): void {
    this.quickCalUrl = window.location.href.split('?')[0];

    this.loan = this.loanUrlService.extractLoanQueryParamsOrHistoryState(this.activeRoute.snapshot, history.state)

    this.loanB = new Loan("B", 40_000.00, 6.25);
    this.loanC = new Loan("C", 45_000.00, 6.25);

    this.openPriceWizard(this.loan);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loan.recalculate();
  }

  goToQuickCalc() {
    this.router.navigateByUrl('home', {state: this.loan})
  }

  /**
   * determine if we need to show the modal dialog window 
   * @param $event
   */
  onTabChanged($event: MatTabChangeEvent) {

    switch ($event.index) {
      case 0:
        this.tabChangeCheckFirstView(this.loan);
        this.currentWizardLoan = this.loan;
        break;
      case 1:
        this.tabChangeCheckFirstView(this.loanB);
        this.currentWizardLoan = this.loanB;
        break;
      case 2:
        this.tabChangeCheckFirstView(this.loanC);
        this.currentWizardLoan = this.loanC;
        break;
      default:
        break;
    }
  }

  /**
   * Check the current loan to see if it's been shown already
   * @param currentLoan
   */
  tabChangeCheckFirstView(currentLoan: Loan) {
    if (!currentLoan.isFirstView()) {
      this.openPriceWizard(currentLoan);
    }
  }

  /**
   * Open the dialog with selected loan
   * @param currentLoan
   */
  openPriceWizard(currentLoan: Loan): void {

    currentLoan.setFirstView();

    this.currentWizardLoan = currentLoan;
    const width = this.breakpointObserver.isMatched(Breakpoints.Handset) ? "100%" : "600px";

    const dialogRef = this.dialog.open(PriceWizardComponent, {
      width: width,
      data: this.currentWizardLoan,
      panelClass: "my-dialog",
      backdropClass: "price-wizard-backdrop",
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.currentWizardLoan = result;
    });
  }
}

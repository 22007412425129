import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { CalculatorComponent } from './calculator/calculator.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { PlaygroundComponent } from './playground/playground.component';
import { PriceWizardComponent } from './price-wizard/price-wizard.component';
import { QuickCalcComponent } from './quick-calculator/quick-calc.component';
import { SupportComponent } from './support/support.component';

const routes: Routes = [
  { path: '', loadChildren: () => import("./landing-page/landing-page.module").then(m => m.LandingPageModule) },
  { path: 'price-wizard', component: PriceWizardComponent },
  { path: 'calculator', component: CalculatorComponent },
  { path: 'quick-calc', component: QuickCalcComponent },
  { path: 'fetch-data', component: FetchDataComponent, canActivate: [AuthGuard] },
  { path: 'support', component: SupportComponent },
  { path: 'playground', component: PlaygroundComponent},
  { path: '', loadChildren: () => import("./identity/identity.module").then(m => m.IdentityModule) },
  { path: '', loadChildren: () => import("./core/core.module").then(m => m.CoreModule) }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { Loan } from './calculator/Loan';

@Injectable({
  providedIn: 'root'
})
export class LoanUrlService {

  constructor() { }

  extractLoanQueryParamsOrHistoryState(routeSnapshot: ActivatedRouteSnapshot, history: any): Loan {
    let currentLoan: Loan;

    currentLoan = this.extractLoanFromQueryParams(routeSnapshot);

    if (currentLoan === undefined) {
      currentLoan = this.extractLoanFromStateHistory(history);
    }

    if (currentLoan === undefined) {
      currentLoan = new Loan("My New Ride", 35_000.00, 6.25);
    }

    return currentLoan;
  }

  extractLoanFromQueryParams(routeSnapshot: ActivatedRouteSnapshot): Loan {

    const queryParams: Params = routeSnapshot.queryParams;

    return this.extractQueryParams(queryParams);
  }

  extractLoanFromRouterState(state: Params): Loan {
    return this.extractQueryParams(state);
  }

  /**
   * extract the loan from the state history
   * */
  private extractLoanFromStateHistory(history:  any): Loan {
    let loan: Loan = undefined;

    if (history != undefined) {
      const name: string = history?.name;
      const price: number = history?.price;
      const stateTaxRate: number = history?.taxRate;
      const loanLength: number = history?.loanLength;
      const loanRate: number = history?.rate;
      const downPayment: number = history?.downPayment;
      const discount: number = history?.discount;
      const tradeIn: number = history?.tradeIn;
      const startDate: Date = history?.startDate;

      if (typeof name != 'undefined' && name) {
        loan = new Loan(name, price, stateTaxRate, loanLength, loanRate, downPayment, discount, tradeIn, startDate);
      }
    }

    return loan;
  }

  /**
   * extract params from a state extract
   * */
  private extractQueryParams(queryParams: Params): Loan {

    let loan: Loan = undefined;

    if (queryParams !== undefined) {
      const name: string = queryParams['name'];
      const price: number = Number(queryParams['price']);
      const stateTaxRate: number = Number(queryParams['taxRate']);
      const loanLength: number = Number(queryParams['loanLength']);
      const loanRate: number = Number(queryParams['rate']);
      const downPayment: number = Number(queryParams['downPayment']);
      const discount: number = Number(queryParams['discount']);
      const tradeIn: number = Number(queryParams['tradeIn']);
      const dateString: string = queryParams['startDate'];

      let startDate: Date = new Date();

      if (dateString !== undefined) {
        startDate = new Date(dateString);
      }

      if (typeof name != 'undefined' && name) {
        loan = new Loan(name, price, stateTaxRate, loanLength, loanRate, downPayment, discount, tradeIn, startDate);
      }
    }

    return loan
  }
}

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.scss']
})
export class LoginButtonComponent implements OnInit {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


  constructor(public auth: AuthService, private breakpointObserver: BreakpointObserver,) { }

  ngOnInit(): void {
  }

  loginWithRedirect(): void {
    this.auth
      .loginWithRedirect({
        async openUrl(url: string) {
          await window.location.replace(`${this.doc.location.origin}/home`);
        }
      })
      .subscribe();
  }
}

import { Component, OnInit, Inject, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Loan } from '../calculator/Loan';
import { PricingService } from '../pricing.service';
import { TaxRate } from '../pricing/TaxRate';

@Component({
  selector: 'app-price-wizard',
  templateUrl: './price-wizard.component.html',
  styleUrls: ['./price-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PriceWizardComponent implements OnInit {

  loan: Loan;
  stateTaxRates: TaxRate[];
  showExplanation: boolean = false;

  constructor(public dialogRef: MatDialogRef<PriceWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Loan,
    private pricingService: PricingService,
    private ref: ChangeDetectorRef) {
    this.loan = data;

    this.stateTaxRates = pricingService.generateTaxRates();
  }

  ngOnInit(): void {

  }

  formatThousands = (value: number) => {
    return this.pricingService.formatThousands(value);
  }

  formatPercent = (value: number) => {
    return this.pricingService.formatPercent(value);
  }

  toggleExplanation(): void {
    this.showExplanation = !this.showExplanation;
  }

  selectTaxRate(value: number) {
    this.loan.updateTaxSelection(value);
    this.ref.markForCheck();
  }

  closePriceWizard(): void {
    this.dialogRef.close(this.loan);
  }
}

<form class="loan-form">
      <div class="container">
        <div class="row loan-page-toggle">
          <div class="col">
          </div>
          <div class="col loan-selected-form">
            <mat-button-toggle-group #loanFormViewGroup="matButtonToggleGroup">
              <mat-button-toggle value="summary" checked="true">
                Summary
              </mat-button-toggle>
              <mat-button-toggle value="schedule">
                Schedule
              </mat-button-toggle>
              <mat-button-toggle value="pricing">
                Price
              </mat-button-toggle>
              <mat-button-toggle value="financing">
                Finance
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col">
          </div>
        </div>
      </div>
      <div class="container loan-form-input-components">
        <loan-summary [loan]="loan" *ngIf="loanFormViewGroup.value == 'summary'" #loanSummary></loan-summary>
        <amoritization-schedule [scheduleData]="loan.amoritizationSchedule" *ngIf="loanFormViewGroup.value == 'schedule'" #loanSchedule></amoritization-schedule>
        <pricing class="mt-2" [loan]="loan" *ngIf="loanFormViewGroup.value == 'pricing'" #loanPricing></pricing>
        <financing [loan]="loan" *ngIf="loanFormViewGroup.value == 'financing'" #loanFinancing></financing>
      </div>
</form>
